














// @ts-ignore
import { VaultItem } from 'vaultifier';
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    item: {
      type: Object as PropType<VaultItem>,
    }
  },
  methods: {
    jsonify(obj: any) {
      return JSON.stringify(obj, null, 2).trim();
    }
  },
  computed: {
    jsonifiedMeta(): string {
      const copy = { ...this.item };
      delete copy.content;

      return this.jsonify(copy);
    },
    jsonifiedData(): string {
      return this.jsonify(this.item.content);
    },
    isEncrypted(): boolean {
      return this.item.isEncrypted;
    }
  }
});
