





























import Vue, { PropType } from 'vue';

import { VaultItem, VaultPostItem } from 'vaultifier';
import RawData from './RawData.vue';

import OcaEditView from '../components/OCAEditView.vue';
import { IStore } from '@/store';
import { ActionType } from '@/store/action-type';

interface Data {
  isSaving: boolean,
}

export default Vue.extend({
  props: {
    item: Object as PropType<VaultItem>,
    showRawView: {
      default: true,
      type: Boolean as PropType<boolean>,
    },
  },
  data: (): Data => ({
    isSaving: false,
  }),
  components: {
    RawData,
    OcaEditView,
  },
  computed: {
    schemaDri(): string | undefined {
      return this.item.schemaDri;
    },
    hasSchema(): boolean {
      return !!this.schemaDri;
    }
  },
  methods: {
    async saveVaultItem(item: VaultPostItem) {
      this.isSaving = true;
      await this.$store.dispatch(ActionType.UPDATE_VAULT_ITEM, item);
      this.isSaving = false;
    }
  }
})
